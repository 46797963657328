import {
    CollectingPlaceSchool,
    CollectingPlaceSchoolsService,
    CollectingPlaceSchoolPatch,
    CollectingPlaceSchoolClassInDB,
    CollectingPlaceSchoolClassPatch,
    CollectingBox,
    DefaultMattelConsignment, Shipment, DHLShipmentOrderRequestShipmentDetails, CollectingPlaceSchoolClassCreate
} from '../services/takebackApi';

export type {
    CollectingPlaceSchool,
    CollectingPlaceSchoolPatch,
    CollectingPlaceSchoolClassInDB,
    CollectingPlaceSchoolClassPatch
}

const {
    getCollectingPlacesCollectingPlacesSchoolsGet,
    getCollectingPlaceClassesCollectingPlacesSchoolsCollectingPlaceIdSchoolClassesGet,
    patchCollectingPlaceCollectingPlacesSchoolsCollectingPlaceIdPatch,
    deleteCollectingPlaceCollectingPlacesSchoolsCollectingPlaceIdDelete,
    patchCollectingPlaceClassCollectingPlacesSchoolsCollectingPlaceIdSchoolClassesCollectingPlaceClassIdPatch,
    createCollectingBoxCollectingPlacesSchoolsCollectingPlaceIdCollectingBoxesPost,
    createConsignmentCollectingPlacesSchoolsCollectingPlaceIdConsignmentPost,
    getCollectingBoxesForPlaceCollectingPlacesSchoolsCollectingPlaceIdCollectingBoxesGet,
    getCollectingPlaceMaterialShipmentCollectingPlacesSchoolsCollectingPlaceIdMaterialShipmentsGet,
    createCollectingBoxShipmentCollectingPlacesSchoolsCollectingPlaceIdCollectingBoxesCollectingBoxIdShipmentsPost,
    createCollectingBoxReturnCollectingPlacesSchoolsCollectingPlaceIdCollectingBoxesCollectingBoxIdReturnsPost,
    createCollectingPlaceClassCollectingPlacesSchoolsCollectingPlaceIdSchoolClassesPost,
} = CollectingPlaceSchoolsService;


export const getCollectingPlaces = async (season: string | null): Promise<Array<CollectingPlaceSchool>> => {
    try {
        const collecting_places = await getCollectingPlacesCollectingPlacesSchoolsGet();
        if (season) {
            return collecting_places.filter(it => it.season === season)
        } else {
            return collecting_places
        }
    } catch (error) {
        throw error
    }
};

export const patchCollectingPlace = async (
    id: string,
    updates: CollectingPlaceSchoolPatch
): Promise<CollectingPlaceSchool> => {
    try {
        return await patchCollectingPlaceCollectingPlacesSchoolsCollectingPlaceIdPatch(id, updates);
    } catch (error) {
        throw error
    }
};

export const deleteCollectingPlace = async (
    collectingPlaceId: string
): Promise<null> => {
    try {
        return await deleteCollectingPlaceCollectingPlacesSchoolsCollectingPlaceIdDelete(collectingPlaceId);
    } catch (error) {
        throw error
    }
};

export const getCollectingPlaceSchoolClasses = async (collectingPlaceId: string): Promise<Array<CollectingPlaceSchoolClassInDB>> => {
    try {
        return await getCollectingPlaceClassesCollectingPlacesSchoolsCollectingPlaceIdSchoolClassesGet(collectingPlaceId)
    } catch (error) {
        throw error
    }
}

export const createCollectingPlaceSchoolClass = async (
    collectingPlaceId: string,
    schoolClassCreate: CollectingPlaceSchoolClassCreate
): Promise<CollectingPlaceSchool> => {
    console.log(schoolClassCreate)
    try {
        return await createCollectingPlaceClassCollectingPlacesSchoolsCollectingPlaceIdSchoolClassesPost(collectingPlaceId, schoolClassCreate);
    } catch (error) {
        throw error
    }
};

export const patchCollectingPlaceSchoolClass = async (
    collectingPlaceId: string,
    schoolClassId: string,
    updates: CollectingPlaceSchoolClassPatch
): Promise<CollectingPlaceSchoolClassInDB> => {
    try {
        return await patchCollectingPlaceClassCollectingPlacesSchoolsCollectingPlaceIdSchoolClassesCollectingPlaceClassIdPatch(collectingPlaceId, schoolClassId, updates);
    } catch (error) {
        throw error
    }
};

export const createConsignment = async (
    collectingPlaceId: string,
    materialWeight: number,
    materialStudentsCount: number
): Promise<DefaultMattelConsignment> => {
    try {
        return await createConsignmentCollectingPlacesSchoolsCollectingPlaceIdConsignmentPost(collectingPlaceId, materialWeight, materialStudentsCount);
    } catch (error) {
        throw error
    }
};

export const getCollectingBoxes = async (collectingPlaceId: string): Promise<Array<CollectingBox>> => {
    try {
        return await getCollectingBoxesForPlaceCollectingPlacesSchoolsCollectingPlaceIdCollectingBoxesGet(collectingPlaceId)
    } catch (error) {
        throw error
    }
}

export const createCollectingBox = async (
    collectingPlaceId: string
): Promise<CollectingBox> => {
    try {
        return await createCollectingBoxCollectingPlacesSchoolsCollectingPlaceIdCollectingBoxesPost(collectingPlaceId);
    } catch (error) {
        throw error
    }
};

export const getMaterialShipments = async (collectingPlaceId: string): Promise<Array<Shipment>> => {
    try {
        return await getCollectingPlaceMaterialShipmentCollectingPlacesSchoolsCollectingPlaceIdMaterialShipmentsGet(collectingPlaceId)
    } catch (error) {
        throw error
    }
}

export const createMaterialShipment = async (
    collectingPlaceId: string,
    referenceNumber: string,
    weight: number,
): Promise<CollectingBox> => {
    try {
        return await createCollectingBoxShipmentCollectingPlacesSchoolsCollectingPlaceIdCollectingBoxesCollectingBoxIdShipmentsPost(
            collectingPlaceId,
            referenceNumber,
            {
                weight: {
                    value: weight
                }
            });
    } catch (error) {
        throw error
    }
};

export const createCollectingBoxShipment = async (
    collectingPlaceId: string,
    collectingBoxId: string,
    weight: number,
): Promise<CollectingBox> => {
    try {
        return await createCollectingBoxShipmentCollectingPlacesSchoolsCollectingPlaceIdCollectingBoxesCollectingBoxIdShipmentsPost(
            collectingPlaceId,
            collectingBoxId,
            {
                weight: {
                    value: weight
                }
            });
    } catch (error) {
        throw error
    }
};

export const createCollectingBoxReturn = async (
    collectingPlaceId: string,
    collectingBoxId: string,
): Promise<CollectingBox> => {
    try {
        return await createCollectingBoxReturnCollectingPlacesSchoolsCollectingPlaceIdCollectingBoxesCollectingBoxIdReturnsPost(collectingPlaceId, collectingBoxId);
    } catch (error) {
        throw error
    }
};
