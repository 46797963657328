import * as React from 'react';
import {useRef} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';
import {
    Box, DialogActions, DialogContent, DialogTitle,
    Grid,
    TextField,
} from "@mui/material";
import {useSnackbar} from "notistack";
import {CollectingPlaceSchool, CollectingPlaceSchoolClassInDB} from "../../services/takebackApi";
import {createCollectingPlaceSchoolClass} from "../../features/collectingPlaces";


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


interface CreationDialogProps {
    collectingPlaceId: string,
    open: boolean,
    onCancel: () => void
    onSubmit: () => void
}

export default function SchoolClassCreationDialog(props: CreationDialogProps) {
    const {enqueueSnackbar} = useSnackbar();

    const formRef = useRef<CreateSchoolClassForm | null>(null);
    const handleCancel = () => {
        props.onCancel()
    }

    const handleCreate = () => {
        if (formRef.current != null) {
            createCollectingPlaceSchoolClass(
                props.collectingPlaceId,
                {
                    className: formRef.current.state.className!!,
                    countStudents: formRef.current.state.countStudents!!,
                    contactInfo: {
                        name: formRef.current.state.name!!,
                        email: formRef.current.state.email!!
                    }
                })
                .then((collectingPlace: CollectingPlaceSchool) => {
                    props.onSubmit()
                })
                .catch((error) => {
                    enqueueSnackbar(error.message, {variant: 'error'});
                })
        }
    }

    return (
        <Dialog
            scroll={"paper"}
            open={props.open}
            onClose={handleCancel}
            TransitionComponent={Transition}
        >
            <DialogTitle>
                {"Create school class"}
            </DialogTitle>
            <DialogContent>
                <Box m={6}>
                    <CreateSchoolClassForm ref={formRef}/>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleCreate} autoFocus>
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    )
}

interface CreateSchoolClassFormState {
    className?: string;
    countStudents?: number;
    name?: string;
    email?: string;
}

class CreateSchoolClassForm extends React.Component<{}, CreateSchoolClassFormState> {
    constructor(props: any) {
        super(props);
        this.state = {
            className: "",
            countStudents: 0,
            name: "",
            email: ""
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    render() {
        return (
            <form>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="className"
                            name="className"
                            value={this.state.className}
                            onChange={this.handleInputChange}
                        />
                        <TextField
                            required
                            label="countStudents"
                            name="countStudents"
                            value={this.state.countStudents}
                            InputProps={{
                                inputMode: 'numeric',
                            }}
                            onChange={this.handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="name"
                            name="name"
                            value={this.state.name}
                            onChange={this.handleInputChange}
                        />
                        <TextField
                            label="email"
                            name="email"
                            value={this.state.email}
                            onChange={this.handleInputChange}
                        />
                    </Grid>
                </Grid>
            </form>
        );
    }
}
