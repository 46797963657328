import {
    CollectingPlaceDaycare,
    CollectingPlaceDaycaresService,
    CollectingPlaceDaycarePatch,
    CollectingBox,
    DefaultNUKConsignment,
    CollectingPlaceDaycareWaitList, Shipment
} from '../services/takebackApi';

export type {
    CollectingPlaceDaycare,
    CollectingPlaceDaycarePatch
}

const {
    getCollectingPlacesCollectingPlacesDaycaresGet,
    patchCollectingPlaceCollectingPlacesDaycaresCollectingPlaceIdPatch,
    deleteCollectingPlaceCollectingPlacesDaycaresCollectingPlaceIdDelete,
    createCollectingBoxCollectingPlacesDaycaresCollectingPlaceIdCollectingBoxesPost,
    createConsignmentCollectingPlacesDaycaresCollectingPlaceIdConsignmentPost,
    getCollectingBoxesForPlaceCollectingPlacesDaycaresCollectingPlaceIdCollectingBoxesGet,
    createCollectingBoxShipmentCollectingPlacesDaycaresCollectingPlaceIdCollectingBoxesCollectingBoxIdShipmentsPost,
    createCollectingBoxReturnCollectingPlacesDaycaresCollectingPlaceIdCollectingBoxesCollectingBoxIdReturnsPost
} = CollectingPlaceDaycaresService;


export const getCollectingPlaces = async (season: string | null): Promise<Array<CollectingPlaceDaycare | CollectingPlaceDaycareWaitList>> => {
    try {
        const collecting_places = await getCollectingPlacesCollectingPlacesDaycaresGet();
        if (season) {
            return collecting_places.filter(it => it.season === season)
        } else {
            return collecting_places
        }
    } catch (error) {
        throw error
    }
};

export const patchCollectingPlace = async (
    id: string,
    updates: CollectingPlaceDaycarePatch
): Promise<CollectingPlaceDaycare> => {
    try {
        return await patchCollectingPlaceCollectingPlacesDaycaresCollectingPlaceIdPatch(id, updates);
    } catch (error) {
        throw error
    }
};

export const deleteCollectingPlace = async (
    collectingPlaceId: string
): Promise<null> => {
    try {
        return await deleteCollectingPlaceCollectingPlacesDaycaresCollectingPlaceIdDelete(collectingPlaceId);
    } catch (error) {
        throw error
    }
};

export const createConsignment = async (
    collectingPlaceId: string
): Promise<DefaultNUKConsignment> => {
    try {
        return await createConsignmentCollectingPlacesDaycaresCollectingPlaceIdConsignmentPost(collectingPlaceId);
    } catch (error) {
        throw error
    }
};

export const getCollectingBoxes = async (collectingPlaceId: string): Promise<Array<CollectingBox>> => {
    try {
        return await getCollectingBoxesForPlaceCollectingPlacesDaycaresCollectingPlaceIdCollectingBoxesGet(collectingPlaceId)
    } catch (error) {
        throw error
    }
}

export const createCollectingBox = async (
    collectingPlaceId: string
): Promise<CollectingBox> => {
    try {
        return await createCollectingBoxCollectingPlacesDaycaresCollectingPlaceIdCollectingBoxesPost(collectingPlaceId);
    } catch (error) {
        throw error
    }
};

export const createCollectingBoxShipment = async (
    collectingPlaceId: string,
    collectingBoxId: string,
    weight: number,
): Promise<CollectingBox> => {
    try {
        return await createCollectingBoxShipmentCollectingPlacesDaycaresCollectingPlaceIdCollectingBoxesCollectingBoxIdShipmentsPost(
            collectingPlaceId,
            collectingBoxId,
            {
                weight: {
                    value: weight
                }
            });
    } catch (error) {
        throw error
    }
};

export const createCollectingBoxReturn = async (
    collectingPlaceId: string,
    collectingBoxId: string,
): Promise<CollectingBox> => {
    try {
        return await createCollectingBoxReturnCollectingPlacesDaycaresCollectingPlaceIdCollectingBoxesCollectingBoxIdReturnsPost(collectingPlaceId, collectingBoxId);
    } catch (error) {
        throw error
    }
};

export const getMaterialShipments = async (collectingPlaceId: string): Promise<Array<Shipment>> => {
    try {
        return []
    } catch (error) {
        throw error
    }
}
