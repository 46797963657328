import * as React from 'react';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {CollectingBox, ReturnOrder, Shipment} from "../../services/takebackApi";
import {Box, Grid} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import Typography from "@mui/material/Typography";

const getShippingStatus = (shipment: (ReturnOrder | Shipment)) => {
    // @ts-ignore
    if ("shipmentReceived" in shipment) {
        // @ts-ignore
        if (shipment.shipmentReceived) {
            return "received";
        }
    }
    if (shipment.shipmentDelivered) {
        return "delivered";
    }
    if (shipment.shipmentSent) {
        return "transit";
    }
    return "pre-transit";
}

function CollectingBoxGroup(props: { collectingBox: CollectingBox }) {
    const {collectingBox} = props;
    const [open, setOpen] = React.useState(true);


    // taken from https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
    const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data)
        const byteArrays = []

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize)

            const byteNumbers = new Array(slice.length)
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i)
            }

            const byteArray = new Uint8Array(byteNumbers)
            byteArrays.push(byteArray)
        }

        return new Blob(byteArrays, {type: contentType})
    }

    const downloadLabel = (label: string) => () => {
        // @ts-ignore
        const file = b64toBlob(label, 'application/pdf')

        const element = document.createElement("a");
        element.href = URL.createObjectURL(file);
        element.download = "labels-" + collectingBox.collectingPlaceId + ".pdf";

        // simulate link click
        document.body.appendChild(element); // Required for this to work in firefox
        element.click();
    }

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Paper sx={{padding: 2, '& > *': {borderBottom: 'unset'}}}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                    <b>
                        Collecting Box {collectingBox.id} ({Intl.DateTimeFormat('de-DE', {
                        year: 'numeric', month: '2-digit', day: '2-digit'
                    }).format(new Date(collectingBox.date))})
                    </b>

                    <Collapse in={open} style={{display: "block"}} timeout="auto" unmountOnExit>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Typ (Aussendung / Retoure)</TableCell>
                                    <TableCell align="left">date</TableCell>
                                    <TableCell align="left">shippingProvider</TableCell>
                                    <TableCell align="left">shippingNumber</TableCell>
                                    <TableCell align="left">status</TableCell>
                                    <TableCell align="left">lastStatusUpdate</TableCell>
                                    <TableCell align="left">shipmentSent</TableCell>
                                    <TableCell align="left">shipmentDelivered</TableCell>
                                    <TableCell align="left">shipmentReceived</TableCell>
                                    <TableCell align="left">shippingLabel</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {collectingBox.returns ? (collectingBox.returns.map((returnOrder) => (
                                    <TableRow key={returnOrder.id}>
                                        <TableCell>{"Retoure"}</TableCell>
                                        <TableCell>{returnOrder.date}</TableCell>
                                        <TableCell>{returnOrder.shipmentData.shipmentProvider}</TableCell>
                                        <TableCell>{returnOrder.shipmentData.shipmentNumber}</TableCell>
                                        <TableCell>{getShippingStatus(returnOrder)}</TableCell>
                                        <TableCell>---</TableCell>
                                        <TableCell>{returnOrder.shipmentSent}</TableCell>
                                        <TableCell>{returnOrder.shipmentDelivered}</TableCell>
                                        <TableCell>{returnOrder.shipmentReceived}</TableCell>
                                        <TableCell>
                                            {returnOrder.shipmentData.labelData ? (
                                                <IconButton aria-label="Label"
                                                            onClick={downloadLabel(returnOrder.shipmentData.labelData)}>
                                                    <PictureAsPdfIcon/>
                                                </IconButton>) : null}
                                        </TableCell>
                                    </TableRow>
                                ))) : null}
                                {collectingBox.shipments ? (collectingBox.shipments.map((shipment) => (
                                    <TableRow key={shipment.id}>
                                        <TableCell>{"Aussendung"}</TableCell>
                                        <TableCell>{shipment.date}</TableCell>
                                        <TableCell>{shipment.shipmentData.shipmentProvider}</TableCell>
                                        <TableCell>{shipment.shipmentData.shipmentNumber}</TableCell>
                                        <TableCell>{getShippingStatus(shipment)}</TableCell>
                                        <TableCell>---</TableCell>
                                        <TableCell>{shipment.shipmentSent}</TableCell>
                                        <TableCell>{shipment.shipmentDelivered}</TableCell>
                                        <TableCell>---</TableCell>
                                        <TableCell>
                                            {shipment.shipmentData.labelData ? (
                                                <IconButton aria-label="Label"
                                                            onClick={downloadLabel(shipment.shipmentData.labelData)}>
                                                    <PictureAsPdfIcon/>
                                                </IconButton>) : null}
                                        </TableCell>
                                    </TableRow>
                                ))) : null}
                            </TableBody>
                        </Table>
                    </Collapse>
                </Paper>
            </Grid>
        </React.Fragment>
    );
}


function MaterialShipmentsGroup(props: { materialShipments: Shipment[] }) {
    const {materialShipments} = props;


    // taken from https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
    const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data)
        const byteArrays = []

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize)

            const byteNumbers = new Array(slice.length)
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i)
            }

            const byteArray = new Uint8Array(byteNumbers)
            byteArrays.push(byteArray)
        }

        return new Blob(byteArrays, {type: contentType})
    }

    const downloadLabel = (label: string) => () => {
        // @ts-ignore
        const file = b64toBlob(label, 'application/pdf')

        const element = document.createElement("a");
        element.href = URL.createObjectURL(file);
        element.download = "labels-" + materialShipments[0].collectingPlaceId + ".pdf";

        // simulate link click
        document.body.appendChild(element); // Required for this to work in firefox
        element.click();
    }

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Paper sx={{padding: 2, '& > *': {borderBottom: 'unset'}}}>
                    <Table aria-label="table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Typ (Aussendung / Retoure)</TableCell>
                                <TableCell align="left">date</TableCell>
                                <TableCell align="left">shippingProvider</TableCell>
                                <TableCell align="left">shippingNumber</TableCell>
                                <TableCell align="left">status</TableCell>
                                <TableCell align="left">lastStatusUpdate</TableCell>
                                <TableCell align="left">shipmentSent</TableCell>
                                <TableCell align="left">shipmentDelivered</TableCell>
                                <TableCell align="left">shipmentReceived</TableCell>
                                <TableCell align="left">shippingLabel</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {materialShipments.map((shipment) => (
                                <TableRow key={shipment.id}>
                                    <TableCell>{"Aussendung"}</TableCell>
                                    <TableCell>{shipment.date}</TableCell>
                                    <TableCell>{shipment.shipmentData.shipmentProvider}</TableCell>
                                    <TableCell>{shipment.shipmentData.shipmentNumber}</TableCell>
                                    <TableCell>{getShippingStatus(shipment)}</TableCell>
                                    <TableCell>---</TableCell>
                                    <TableCell>{shipment.shipmentSent}</TableCell>
                                    <TableCell>{shipment.shipmentDelivered}</TableCell>
                                    <TableCell>---</TableCell>
                                    <TableCell>
                                        {shipment.shipmentData.labelData ? (
                                            <IconButton aria-label="Label"
                                                        onClick={downloadLabel(shipment.shipmentData.labelData)}>
                                                <PictureAsPdfIcon/>
                                            </IconButton>) : null}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </Grid>
        </React.Fragment>
    );
}

interface CollectingPlaceShippingOverviewProps {
    collectingPlaceId: string
    getCollectingBoxes: (collectingPlaceId: string) => Promise<CollectingBox[]>
    getMaterialShipments: (collectingPlaceId: string) => Promise<Shipment[]>
}


export default function CollectingPlaceShippingOverview(props: CollectingPlaceShippingOverviewProps) {
    const {enqueueSnackbar} = useSnackbar();

    const [collectingBoxes, setCollectingBoxes] = useState<CollectingBox[]>([]);
    const [materialShipments, setMaterialShipments] = useState<Shipment[]>([]);

    useEffect(() => {
        reloadCollectingBoxes()
        reloadMaterialShipments()
    }, [props.collectingPlaceId])

    const reloadCollectingBoxes = () => {
        props.getCollectingBoxes(props.collectingPlaceId)
            .then((allCollectingBoxes) => {
                setCollectingBoxes(allCollectingBoxes)
            })
            .catch((error) => {
                enqueueSnackbar(error.message, {variant: 'error'});
            })
    }
    const reloadMaterialShipments = () => {
        props.getMaterialShipments(props.collectingPlaceId)
            .then((allMaterialShipments) => {
                setMaterialShipments(allMaterialShipments)
            })
            .catch((error) => {
                enqueueSnackbar(error.message, {variant: 'error'});
            })
    }

    return (
        <Grid container spacing={2}>
            <Box display="flex" flexDirection={"column"} p={2} pt={4} width={"100%"}>
                <Typography variant={"h5"}>Sammelboxen</Typography>
                {collectingBoxes.length == 0 ? (
                    <Typography variant={"subtitle1"}>Keine Sammelboxen vorhanden.</Typography>
                ): (collectingBoxes.map((collectingBox) => (
                    <CollectingBoxGroup key={collectingBox.id} collectingBox={collectingBox}/>
                )))}
            </Box>
            <Box display="flex" flexDirection={"column"} p={2} pt={4} width={"100%"}>
                <Typography variant={"h5"}>Andere Aussendungen</Typography>
                {materialShipments.length == 0 ? (
                    <Typography variant={"subtitle1"}>Keine Materialsendungen vorhanden.</Typography>
                ): (
                    <MaterialShipmentsGroup materialShipments={materialShipments}/>
                )}
            </Box>
        </Grid>
    );
}
