import {
    GridActionsCellItem,
    GridColDef,
    GridRowModel,
    GridRowParams,
} from "@mui/x-data-grid";
import React, {useEffect, useState} from "react";
import renderCellExpand from "../../../common/tables/GridCellExpand";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
    CollectingPlaceOther,
    deleteCollectingPlaceOther,
    getCollectingPlaceOthers,
    patchCollectingPlaceOther,
    getCollectingBoxes,
    createCollectingBox,
    createCollectingBoxReturn,
    createCollectingBoxShipment
} from "../../features/collectingPlaceOthers";
import {useSnackbar} from "notistack";
import DataList from "../../../common/tables/DataList";
import DefaultConsignmentDialog from "./DefaultConsignmentDialog";
import DeletionDialog from "./DeletionDialog";
import germanPostalCodes from '../../../common/germanPostalCodes';
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import OtherCreationDialog from "./OtherCreationDialog";
import ShippingDialog from "./ShippingDialog";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";

function CollectingPlaceOthersList() {
    const {enqueueSnackbar} = useSnackbar();

    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [collectingPlaces, setCollectingPlaces] = useState<CollectingPlaceOther[]>([]);

    const [shippingDialogOpen, setShippingDialogOpen] = useState(false)
    const [shippingDialogId, setShippingDialogId] = useState("")

    const [consignmentDialogOpen, setConsignmentDialogOpen] = useState(false)
    const [consignmentDialogId, setConsignmentDialogId] = useState("")

    const [deletionDialogOpen, setDeletionDialogOpen] = useState(false)
    const [deletionDialogId, setDeletionDialogId] = useState("")

    const [creationDialogOpen, setCreationDialogOpen] = useState(false)

    const handleCreateClick = () => {
        setCreationDialogOpen(true)
    }

    const handleCreationDialogCancel = () => {
        setCreationDialogOpen(false)
    }

    const handleCreationDialogSubmit = () => {
        enqueueSnackbar('Änderungen erfolgreich gespeichert', {variant: 'success'})
        reloadCollectingPlaces()
        setCreationDialogOpen(false)
    }

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 175,
        },
        {
            field: 'company',
            headerName: 'company',
            minWidth: 150,
            flex: 1,
            editable: true,
            renderCell: renderCellExpand
        },
        {
            field: 'name1',
            headerName: 'name1',
            minWidth: 150,
            flex: 1,
            editable: true,
            valueGetter: (params) => params.row.collectingPlaceAddress.name1,
            renderCell: renderCellExpand
        },
        {
            field: 'name2',
            headerName: 'name2',
            minWidth: 150,
            flex: 1,
            editable: true,
            valueGetter: (params) => params.row.collectingPlaceAddress.name2,
            renderCell: renderCellExpand
        },
        {
            field: 'name3',
            headerName: 'name3',
            minWidth: 150,
            flex: 1,
            editable: true,
            valueGetter: (params) => params.row.collectingPlaceAddress.name3,
            renderCell: renderCellExpand
        },
        {
            field: 'streetName',
            headerName: 'streetName',
            minWidth: 150,
            flex: 1,
            editable: true,
            valueGetter: (params) => params.row.collectingPlaceAddress.streetName,
            renderCell: renderCellExpand
        },
        {
            field: 'houseNumber',
            headerName: 'houseNumber',
            minWidth: 150,
            flex: 1,
            editable: true,
            valueGetter: (params) => params.row.collectingPlaceAddress.houseNumber,
            renderCell: renderCellExpand
        },
        {
            field: 'postCode',
            headerName: 'postCode',
            minWidth: 150,
            flex: 1,
            editable: true,
            valueGetter: (params) => params.row.collectingPlaceAddress.postCode,
            renderCell: renderCellExpand
        },
        {
            field: 'city',
            headerName: 'city',
            minWidth: 150,
            flex: 1,
            editable: true,
            valueGetter: (params) => params.row.collectingPlaceAddress.city,
            renderCell: renderCellExpand
        },
        {
            field: 'state',
            headerName: 'state',
            valueGetter: (params) => {
                return findState(params.row.collectingPlaceAddress.postCode)
            },
            type: 'text',
        },
        {
            field: 'date',
            headerName: 'date',
            type: 'dateTime',
            width: 230,
            valueGetter: ({value}) => value && new Date(value),
        },
        {
            field: 'shippingActions',
            type: 'actions',
            headerName: 'Shipping',
            // @ts-ignore
            getActions: (params: GridRowParams) => {
                let actions: Array<typeof GridActionsCellItem> = []
                // @ts-ignore
                actions.push(<GridActionsCellItem icon={<LocalPostOfficeIcon/>} label="Shipping"
                                                  onClick={openShippingDialog(params.row.id)} showInMenu={false}/>,)
                return actions
            }
        },
        {
            field: 'actions',
            type: 'actions',
            // @ts-ignore
            getActions: (params: GridRowParams) => {
                let actions: Array<typeof GridActionsCellItem> = []
                // @ts-ignore
                actions.push(<GridActionsCellItem icon={<LocalShippingIcon/>} label="Kommissionierung"
                                                  onClick={openConsignmentDialog(params.row.id)} showInMenu={false}/>,)
                // @ts-ignore
                actions.push(<GridActionsCellItem icon={<DeleteForeverIcon/>} label="Löschen"
                                                  onClick={openDeletionDialog(params.row.id)} showInMenu={false}/>,)
                return actions
            }
        }
    ]

    useEffect(() => {
        reloadCollectingPlaces()
    }, [])

    const reloadCollectingPlaces = () => {
        setIsLoading(true)

        getCollectingPlaceOthers(sessionStorage.getItem("season"))
            .then((allCollectingPlaces) => {
                setCollectingPlaces(allCollectingPlaces)
                setIsLoading(false)
            })
            .catch((error) => {
                enqueueSnackbar(error.message, {variant: 'error'});
            })
    }

    const processRowUpdate = async (newRow: GridRowModel) => {
        const response = await patchCollectingPlaceOther(newRow.id, {
            company: newRow.company,
            collectingPlaceAddress: {
                name1: newRow.name1,
                name2: newRow.name2,
                name3: newRow.name3,
                streetName: newRow.streetName,
                houseNumber: newRow.houseNumber,
                postCode: newRow.postCode,
                city: newRow.city,
            },
        })
        enqueueSnackbar('Änderungen erfolgreich gespeichert', {variant: 'success'})
        return response
    }

    const openShippingDialog = (collectingPlaceId: string) => () => {
        setShippingDialogId(collectingPlaceId)
        setShippingDialogOpen(true)
    }
    const handleShippingDialogClose = () => {
        setShippingDialogOpen(false)
    }

    const openConsignmentDialog = (collectingPlaceId: string) => () => {
        setConsignmentDialogId(collectingPlaceId)
        setConsignmentDialogOpen(true)
    }
    const handleConsignmentDialogClose = () => {
        setConsignmentDialogOpen(false)
    }

    const openDeletionDialog = (collectingPlaceId: string) => () => {
        setDeletionDialogId(collectingPlaceId)
        setDeletionDialogOpen(true)
    }
    const handleDeletionDialogConfirm = async () => {
        const response = await deleteCollectingPlaceOther(deletionDialogId)
        enqueueSnackbar('Änderungen erfolgreich gespeichert', {variant: 'success'})
        reloadCollectingPlaces()
        return response
    }
    const handleDeletionDialogClose = () => {
        setDeletionDialogOpen(false)
    }

    const findState = (postalCode: string): string | null => {
        const pattern = new RegExp(`;([^;]*?);${postalCode};[^;]*?;([^;]*?)$`, 'm')
        const match = pattern.exec(germanPostalCodes)
        if (match) {
            return match[2]
        }
        return null
    }

    return (
        <>
            <DataList
                items={collectingPlaces}
                columns={columns}
                isLoading={isLoading}
                processRowUpdate={processRowUpdate}
                exportOptions={{
                    fileName: `export-mattel-others-${new Date().toJSON().slice(0, 10)}`
                }}
            />
            <ShippingDialog
                collectingPlaceId={shippingDialogId}
                open={shippingDialogOpen}
                onClose={handleShippingDialogClose} />
            <DefaultConsignmentDialog
                collectingPlaceId={consignmentDialogId}
                open={consignmentDialogOpen}
                onClose={handleConsignmentDialogClose}
                getCollectingBoxes={getCollectingBoxes}
                createCollectingBox={createCollectingBox}
                createCollectingBoxReturn={createCollectingBoxReturn}
                createCollectingBoxShipment={createCollectingBoxShipment}/>
            <DeletionDialog
                collectingPlaceId={deletionDialogId}
                open={deletionDialogOpen}
                onConfirm={handleDeletionDialogConfirm}
                onClose={handleDeletionDialogClose}/>
            <Fab color="primary" aria-label="add" onClick={handleCreateClick}>
                <AddIcon/>
            </Fab>
            <OtherCreationDialog open={creationDialogOpen}
                                  onCancel={handleCreationDialogCancel}
                                  onSubmit={handleCreationDialogSubmit}/>
        </>
    )
}

export default CollectingPlaceOthersList
