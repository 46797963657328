import * as React from 'react';
import {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';
import {
    Box,
} from "@mui/material";
import {useSnackbar} from "notistack";
import {
    getCollectingBoxes,
    getMaterialShipments
} from "../../features/collectingPlaces";
import CollectingPlaceShippingOverview from "./CollectingPlaceShippingOverview";


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


interface ShippingDialogProps {
    collectingPlaceId: string
    open: boolean,
    onClose: () => void
}

export default function ShippingDialog(props: ShippingDialogProps) {
    const {enqueueSnackbar} = useSnackbar();

    const [lastUpdate, setLastUpdate] = useState<number>(0)

    const handleUpdate = (time: number) => {
        setLastUpdate(time)
    }

    const handleClose = () => {
        props.onClose()
    }

    useEffect(() => {

    }, [props.open, props.collectingPlaceId])

    return (
        <Dialog
            fullScreen
            scroll={"paper"}
            open={props.open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{position: 'relative'}}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                    <Typography sx={{ml: 2, flex: 1}} variant="h5" component="div">
                        Shipping details for collecting place {props.collectingPlaceId}
                    </Typography>
                    <Button autoFocus color="inherit" size="large" onClick={handleClose}>
                        <Typography variant="h6">Close</Typography>
                    </Button>
                </Toolbar>
            </AppBar>
            <Box m={6}
                 component="form"
                 autoComplete="off"
                 sx={{height: "100%"}}>
                <CollectingPlaceShippingOverview
                    collectingPlaceId={props.collectingPlaceId}
                    getCollectingBoxes={getCollectingBoxes}
                    getMaterialShipments={getMaterialShipments}/>
            </Box>
        </Dialog>
    )
}
