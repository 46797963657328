import {
    GridActionsCellItem,
    GridColDef,
    GridRowModel,
    GridRowParams,
} from "@mui/x-data-grid";
import React, {useEffect, useState} from "react";
import renderCellExpand from "../../../common/tables/GridCellExpand";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
    deleteCollectingPlace,
    getCollectingPlaces,
    patchCollectingPlace
} from "../../features/collectingPlaces";
import {useSnackbar} from "notistack";
import DataList from "../../../common/tables/DataList";
import DaycareConsignmentDialog from "./DaycareConsignmentDialog";
import DeletionDialog from "./DeletionDialog";
import germanPostalCodes from '../../../common/germanPostalCodes';
import {CollectingPlaceDaycare, CollectingPlaceDaycareWaitList} from "../../services/takebackApi";
import ShippingDialog from "./ShippingDialog";
import GroupsIcon from "@mui/icons-material/Groups";

function CollectingPlaceDaycaresList() {
    const {enqueueSnackbar} = useSnackbar();

    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [collectingPlaces, setCollectingPlaces] = useState<Array<CollectingPlaceDaycare | CollectingPlaceDaycareWaitList>>([]);

    const [shippingDialogOpen, setShippingDialogOpen] = useState(false)
    const [shippingDialogId, setShippingDialogId] = useState("")

    const [consignmentDialogOpen, setConsignmentDialogOpen] = useState(false)
    const [consignmentDialogId, setConsignmentDialogId] = useState("")

    const [deletionDialogOpen, setDeletionDialogOpen] = useState(false)
    const [deletionDialogId, setDeletionDialogId] = useState("")

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 175,
        },
        {
            field: 'company',
            headerName: 'company',
            minWidth: 150,
            flex: 1,
            editable: true,
            renderCell: renderCellExpand
        },
        {
            field: 'name',
            headerName: 'contactInfo.name',
            minWidth: 150,
            flex: 1,
            editable: true,
            valueGetter: (params) => params.row.contactInfo && params.row.contactInfo.name,
            renderCell: renderCellExpand
        },
        {
            field: 'email',
            headerName: 'contactInfo.email',
            minWidth: 150,
            flex: 1,
            editable: true,
            valueGetter: (params) => params.row.contactInfo && params.row.contactInfo.email,
            renderCell: renderCellExpand
        },
        {
            field: 'name1',
            headerName: 'name1',
            minWidth: 150,
            flex: 1,
            editable: true,
            valueGetter: (params) => params.row.collectingPlaceAddress ? params.row.collectingPlaceAddress.name1 : "",
            renderCell: renderCellExpand
        },
        {
            field: 'name2',
            headerName: 'name2',
            minWidth: 150,
            flex: 1,
            editable: true,
            valueGetter: (params) => params.row.collectingPlaceAddress ? params.row.collectingPlaceAddress.name2 : "",
            renderCell: renderCellExpand
        },
        {
            field: 'name3',
            headerName: 'name3',
            minWidth: 150,
            flex: 1,
            editable: true,
            valueGetter: (params) => params.row.collectingPlaceAddress ? params.row.collectingPlaceAddress.name3 : "",
            renderCell: renderCellExpand
        },
        {
            field: 'streetName',
            headerName: 'streetName',
            minWidth: 150,
            flex: 1,
            editable: true,
            valueGetter: (params) => params.row.collectingPlaceAddress ? params.row.collectingPlaceAddress.streetName : "",
            renderCell: renderCellExpand
        },
        {
            field: 'houseNumber',
            headerName: 'houseNumber',
            minWidth: 150,
            flex: 1,
            editable: true,
            valueGetter: (params) => params.row.collectingPlaceAddress ? params.row.collectingPlaceAddress.houseNumber : "",
            renderCell: renderCellExpand
        },
        {
            field: 'postCode',
            headerName: 'postCode',
            minWidth: 150,
            flex: 1,
            editable: true,
            valueGetter: (params) => params.row.collectingPlaceAddress ? params.row.collectingPlaceAddress.postCode : "",
            renderCell: renderCellExpand
        },
        {
            field: 'city',
            headerName: 'city',
            minWidth: 150,
            flex: 1,
            editable: true,
            valueGetter: (params) => params.row.collectingPlaceAddress ? params.row.collectingPlaceAddress.city : "",
            renderCell: renderCellExpand
        },
        {
            field: 'state',
            headerName: 'state',
            valueGetter: (params) => {
                return params.row.collectingPlaceAddress ? findState(params.row.collectingPlaceAddress.postCode) : ""
            },
            type: 'text',
        },
        {
            field: 'countryISOCode',
            headerName: 'country',
            minWidth: 50,
            flex: 1,
            editable: true,
            valueGetter: (params) => params.row.collectingPlaceAddress ? params.row.collectingPlaceAddress.countryISOCode : params.row.countryISOCode,
            renderCell: renderCellExpand
        },
        {
            field: 'hasCollectingBox',
            headerName: 'CB from Y2?',
            type: 'boolean',
            editable: false,
        },
        {
            field: 'closingTimeStart',
            headerName: 'closingTimeStart',
            type: 'dateTime',
            width: 230,
            valueGetter: (params) => params.row.collectingPlaceDetails ? (params.row.collectingPlaceDetails.closingTimeStart &&  new Date(params.row.collectingPlaceDetails.closingTimeStart)) : "",
        },
        {
            field: 'closingTimeEnd',
            headerName: 'closingTimeEnd',
            type: 'dateTime',
            width: 230,
            valueGetter: (params) => params.row.collectingPlaceDetails ? (params.row.collectingPlaceDetails.closingTimeEnd &&  new Date(params.row.collectingPlaceDetails.closingTimeEnd)) : "",
        },
        {
            field: 'hasPotentialDuplicates',
            headerName: 'Duplicates?',
            valueGetter: (params) => {
                return collectingPlaces.some((collectingPlace) =>
                    collectingPlace.id !== params.row.id &&
                    "collectingPlaceAddress" in collectingPlace &&
                    collectingPlace.collectingPlaceAddress &&
                    params.row.collectingPlaceAddress &&
                    collectingPlace.collectingPlaceAddress.postCode === params.row.collectingPlaceAddress.postCode)
            },
            type: 'boolean',
        },
        {
            field: 'verified',
            headerName: 'verified',
            type: 'boolean',
        },
        {
            field: 'date',
            headerName: 'date',
            type: 'dateTime',
            width: 230,
            valueGetter: ({ value }) => value && new Date(value),
        },
        {
            field: 'confirmation',
            headerName: 'DOI',
            type: 'dateTime',
            width: 230,
            valueGetter: ({ value }) => value && new Date(value),
        },
        {
            field: 'waitList',
            headerName: 'waitList',
            type: 'boolean',
        },
        {
            field: 'shippingActions',
            type: 'actions',
            headerName: 'Shipping',
            // @ts-ignore
            getActions: (params: GridRowParams) => {
                let actions: Array<typeof GridActionsCellItem> = []
                // @ts-ignore
                actions.push(<GridActionsCellItem icon={<LocalPostOfficeIcon/>} label="Shipping"
                                                  onClick={openShippingDialog(params.row.id)} showInMenu={false}/>,)
                return actions
            }
        },
        {
            field: 'actions',
            type: 'actions',
            // @ts-ignore
            getActions: (params: GridRowParams) => {
                let actions: Array<typeof GridActionsCellItem> = []
                if(!params.row.waitList) {
                    if(!params.row.verified) {
                        // @ts-ignore
                        actions.push( <GridActionsCellItem icon={<DoneAllIcon/>} label="Gecheckt"
                                                            onClick={markAsVerified(params.row.id)} showInMenu={false}/>)
                    } else {
                        // @ts-ignore
                        actions.push(<GridActionsCellItem icon={<LocalShippingIcon/>} label="Kommissionierung"
                                                        onClick={openConsignmentDialog(params.row.id)} showInMenu={false}/>,)
                    }
                }
                // @ts-ignore
                actions.push(<GridActionsCellItem icon={<DeleteForeverIcon/>} label="Löschen"
                                                  onClick={openDeletionDialog(params.row.id)} showInMenu={false}/>,)
                return actions
            }
        }
    ]

    useEffect(() => {
        reloadCollectingPlaces()
    }, [])

    const reloadCollectingPlaces = () => {
        setIsLoading(true)

        getCollectingPlaces(sessionStorage.getItem("season"))
            .then((allCollectingPlaces) => {
                setCollectingPlaces(allCollectingPlaces)
                setIsLoading(false)
            })
            .catch((error) => {
                enqueueSnackbar(error.message, {variant: 'error'});
            })
    }

    const processRowUpdate = async (newRow: GridRowModel) => {
        if (!newRow.waitList) {
            const response = await patchCollectingPlace(newRow.id, {
                company: newRow.company,
                contactInfo: {
                    name: newRow.name,
                    email: newRow.email,
                },
                collectingPlaceAddress: {
                    name1: newRow.name1,
                    name2: newRow.name2,
                    name3: newRow.name3,
                    streetName: newRow.streetName,
                    houseNumber: newRow.houseNumber,
                    postCode: newRow.postCode,
                    city: newRow.city,
                    countryISOCode: newRow.countryISOCode,
                }
            })
            enqueueSnackbar('Änderungen erfolgreich gespeichert', {variant: 'success'})
            return response
        } else {
            enqueueSnackbar('Wartelistenkitas können nicht bearbeitet werden', {variant: 'error'})
        }
    }

    const markAsVerified = (collectingPlaceId: string) => async () => {
        const response = await patchCollectingPlace(collectingPlaceId, {
            verified: new Date().toISOString(),
        })
        enqueueSnackbar('Änderungen erfolgreich gespeichert', {variant: 'success'})
        reloadCollectingPlaces()
        return response
    }

    const openShippingDialog = (collectingPlaceId: string) => () => {
        setShippingDialogId(collectingPlaceId)
        setShippingDialogOpen(true)
    }
    const handleShippingDialogClose = () => {
        setShippingDialogOpen(false)
    }
    const openConsignmentDialog = (collectingPlaceId: string) => () => {
        setConsignmentDialogId(collectingPlaceId)
        setConsignmentDialogOpen(true)
    }
    const handleConsignmentDialogClose = () => {
        setConsignmentDialogOpen(false)
    }

    const openDeletionDialog = (collectingPlaceId: string) => () => {
        setDeletionDialogId(collectingPlaceId)
        setDeletionDialogOpen(true)
    }
    const handleDeletionDialogConfirm = async () => {
        const response = await deleteCollectingPlace(deletionDialogId)
        enqueueSnackbar('Änderungen erfolgreich gespeichert', {variant: 'success'})
        reloadCollectingPlaces()
        return response
    }
    const handleDeletionDialogClose = () => {
        setDeletionDialogOpen(false)
    }

    const findState = (postalCode: string) : string | null => {
        const pattern = new RegExp(`;([^;]*?);${postalCode};[^;]*?;([^;]*?)$`, 'm')
        const match = pattern.exec(germanPostalCodes)
        if (match) {
            return match[2]
        }
        return null
    }

    return (
        <>
            <DataList
                items={collectingPlaces}
                columns={columns}
                isLoading={isLoading}
                processRowUpdate={processRowUpdate}
                exportOptions={{
                    fileName: `export-nuk-daycares-${new Date().toJSON().slice(0, 10)}`
                }}
            />
            <ShippingDialog
                collectingPlaceId={shippingDialogId}
                open={shippingDialogOpen}
                onClose={handleShippingDialogClose} />
            <DaycareConsignmentDialog
                collectingPlaceId={consignmentDialogId}
                open={consignmentDialogOpen}
                onClose={handleConsignmentDialogClose} />
            <DeletionDialog
                collectingPlaceId={deletionDialogId}
                open={deletionDialogOpen}
                onConfirm={handleDeletionDialogConfirm}
                onClose={handleDeletionDialogClose} />
        </>
    )
}

export default CollectingPlaceDaycaresList
